import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const AuthView = () => import("@/views/AuthView.vue");
const MainView = () => import("@/views/MainView.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const DashboardCA = () => import("../views/DashboardCA.vue");

const roles = store.getters.user_role;
const count_stations = store.getters.count_stations;

const routes = [
  {
    path: "/",
    name: "main",
    meta: {
      title: "TS Charge",
      requiresAuth: true,
    },
    component: MainView,
    redirect: { name: "dashboard" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: `Добро пожаловать`,
      icon: "",
      refresh: true,
    },
    // component: roles?.find((o) => o.name === "Администратор")
    //   ? Dashboard
    //   : DashboardCA,
    component: (roles?.find((o) => o.name === "Администратор") || count_stations > 0)
      ? Dashboard
      : DashboardCA,
  },
  // stations
  {
    path: "/stations",
    name: "stations",
    meta: {
      title: "Станции",
      icon: "",
      addBtn: {
        show: true,
        path: "/stations/add",
      },
    },
    component: () => import("../views/stations/Stations/Stations.vue"),
  },
  {
    path: "/stations/:id",
    name: "station-details",
    meta: {
      title: "Детальная ЭЗС",
      icon: "",
      addBtn: {
        show: true,
        path: "/stations/add",
      },
    },
    props: true,
    component: () => import("../views/stations/Stations/StationDetails.vue"),
  },
  {
    path: "/stations/edit/:id",
    name: "station-edit",
    meta: {
      title: "Изменить ЭЗС",
      icon: "",
      addBtn: {
        show: true,
        path: "/stations/add",
      },
    },
    props: true,
    component: () => import("../views/stations/Stations/StationAddEdit.vue"),
  },
  {
    path: "/stations/add",
    name: "station-add",
    meta: {
      title: "Добавление ЭЗС",
      icon: "",
    },
    component: () => import("../views/stations/Stations/StationAddEdit.vue"),
  },
  {
    path: "/stations/status-journal",
    name: "station-status-journal",
    meta: {
      title: "Журнал статусов",
      icon: "",
    },
    component: () =>
      import("../views/stations/StatusJournal/StatusJournal.vue"),
  },
  {
    path: "/stations/status-journal/:id",
    name: "station-status-journal-detail",
    meta: {
      title: "Журнал статусов",
      icon: "",
    },
    component: () =>
      import("../views/stations/StatusJournal/StatusJournalDetails.vue"),
  },
  {
    path: "/stations/control",
    name: "station-control",
    meta: {
      title: "Управление ЭЗС",
      icon: "",
    },
    component: () => import("../views/stations/Controls/Control.vue"),
  },
  {
    path: "/stations/control/:id",
    name: "task-details",
    meta: {
      title: "Задача",
      icon: "",
    },
    component: () =>
      import("../views/stations/Controls/ControlTaskDetails.vue"),
  },
  {
    path: "/stations/transactions",
    name: "station-transactions",
    meta: {
      title: "Сессии",
      icon: "",
    },
    component: () => import("../views/stations/Transactions/Transactions.vue"),
  },
  {
    path: "/stations/transactions/:id",
    name: "station-transactions-details",
    meta: {
      title: "Детальная транзакции",
      icon: "",
    },
    component: () =>
      import("../views/stations/Transactions/TransactionDetails.vue"),
  },

  {
    path: "/monitoring",
    name: "monitoring-name",
    meta: {
      title: "Сессии",
      icon: "",
    },
    component: () => import("../views/InDevelop.vue"),
  },
  {
    path: "/charge-profile",
    name: "charge-profile-main",
    meta: {
      title: "Профили зарядки",
      icon: "",
    },
    component: () => import("../views/InDevelop.vue"),
  },
  {
    path: "/sessions",
    name: "sessions-name",
    meta: {
      title: "Сессии",
      icon: "",
    },
    component: () => import("../views/InDevelop.vue"),
  },
  // users
  {
    path: "/users/partners",
    name: "user-partners",
    meta: {
      title: "Партнеры",
      icon: "",
      addBtn: {
        show: true,
        path: {
          name: "user-employee-add",
          params: { user: "employee" },
        },
      },
    },
    component: () => import("../views/users/PageUsers.vue"),
  },
  {
    path: "/users/employees",
    name: "user-employees",
    meta: {
      title: "Сотрудники",
      icon: "",
      addBtn: {
        show: true,
        path: {
          name: "user-employee-add",
          params: { user: "employee" },
        },
      },
    },
    component: () => import("../views/users/PageUsers.vue"),
  },
  {
    path: "/users/clients",
    name: "user-clients",
    meta: {
      title: "Клиенты",
      icon: "",
    },
    component: () => import("../views/users/Clients.vue"),
  },
  // members
  {
    path: "/users/members",
    name: "members",
    meta: {
      title: "Клиенты",
      icon: "",
      addBtn: {
        show: true,
        path: {
          name: "members-add",
          params: { user: "members" },
        },
      },
    },
    component: () => import("../views/users/Members.vue"),
  },
  {
    path: "/users/members/:id",
    name: "members-details",
    meta: {
      parent: "members",
    },
    component: () => import("../views/users/MembersDetails.vue"),
  },
  {
    path: "/users/members/add/:user",
    name: "members-add",
    meta: {
      title: "Добавление",
      icon: "",
      parent: "members",
    },
    component: () => import("../views/users/MembersAdd.vue"),
  },
  {
    path: "/users/members/edit/:id",
    name: "members-edit",
    meta: {
      title: "Редактирование",
      icon: "",
      parent: "members-details",
    },
    component: () => import("../views/users/MembersEdit.vue"),
  },
  // clients
  {
    path: "/users/clients/:id",
    name: "user-details",
    meta: {
      title: "",
      icon: "",
      addBtn: {
        show: true,
        path: {
          name: "user-employee-add",
          params: { user: "employee" },
        },
      },
    },
    component: () => import("../views/users/PageDetailsUser.vue"),
  },
  {
    path: "/users/add/:user",
    name: "user-employee-add",
    meta: {
      title: "Добавление",
      icon: "",
    },
    component: () => import("../views/users/PageAddEditUser.vue"),
  },
  {
    path: "/users/profile",
    name: "user-profile",
    meta: {
      title: "",
      icon: "",
    },
    component: () => import("../views/InDevelop.vue"),
  },
  // static
  {
    path: "/statistics",
    name: "statistics",
    meta: {
      title: "Обзор",
      icon: "",
    },
    component: () => import("../views/statistics/StatisticsView.vue"),
  },
  {
    path: "/statistics/consumption",
    name: "statistic-consumption",
    meta: {
      title: "Потребление",
      icon: "",
    },
    component: () => import("../views/statistics/Consumption/Consumption.vue"),
  },
  {
    path: "/statistics/consumption/station/:id",
    name: "station",
    meta: {
      title: "Потребление",
      icon: "",
      parent: "statistic-consumption",
    },
    component: () =>
      import("../views/statistics/Consumption/StationDetails.vue"),
  },
  {
    path: "/statistics/consumption/connector_type/:id",
    name: "connector_type",
    meta: {
      title: "Потребление",
      icon: "",
      parent: "statistic-consumption",
    },
    component: () =>
      import("../views/statistics/Consumption/ConnectorTypeDetails.vue"),
  },
  {
    path: "/statistics/consumption/user/:id",
    name: "user",
    meta: {
      title: "Потребление",
      icon: "",
      parent: "statistic-consumption",
    },
    component: () => import("../views/statistics/Consumption/UserDetails.vue"),
  },
  // {
  //   path: "/statistics/consumption/transaction/:id",
  //   name: "transaction",
  //   meta: {
  //     title: "Потребление",
  //     icon: "",
  //     parent: "statistic-consumption",
  //   },
  //   component: () =>
  //     import("../views/statistics/Consumption/TransactionDetails.vue"),
  // },
  {
    path: "/statistics/sessions",
    name: "statistic-sessions",
    meta: {
      title: "Сессии",
      icon: "",
    },
    component: () => import("../views/statistics/Sessions.vue"),
  },
  {
    path: "/statistics/duration",
    name: "statistic-duration",
    meta: {
      title: "Продолжительность",
      icon: "",
    },
    component: () => import("../views/statistics/Duration.vue"),
  },
  {
    path: "/statistics/reservations",
    name: "statistic-reservation",
    meta: {
      title: "Резирвирования",
      icon: "",
    },
    component: () => import("../views/statistics/StatisticsView.vue"),
  },
  {
    path: "/statistics/calculations",
    name: "statistic-calculations",
    meta: {
      title: "Расчеты",
      icon: "",
    },
    component: () => import("../views/statistics/StatisticsView.vue"),
  },
  {
    path: "/auth/login",
    name: "login",
    component: AuthView,
    meta: {
      title: "Авторизация",
    },
  },
  {
    path: "/auth/confirm",
    name: "confirm",
    component: AuthView,
    meta: {
      title: "Подтверждение Email",
    },
  },
  {
    path: "/auth/register",
    name: "register",
    component: AuthView,
    meta: {
      title: "Регистрация партнера",
    },
  },
  {
    path: "/auth",
    redirect: { name: "login" },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import("../views/Forbidden.vue"),
    meta: {
      title: "",
    },
  },

  // { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("checkUserStatus").then(() => {
    const userLoggedIn = store.getters.userLoggedIn;
    if (!userLoggedIn && !to.path.includes("/auth")) {
      next({ name: "auth" });
    } else {
      next();
    }
  });
});

export default router;
